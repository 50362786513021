<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>SMS</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <!--
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                -->
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items.sync="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        item-key="ProfileID"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ID}}</td>
                            <td>{{props.item.number}}</td>
                            <td>{{props.item.fullmessage}}</td>
                            <td>{{props.item.createdDateTime}}</td>
                            <td>{{props.item.status}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                    <span v-if="validateAccess('businesscpo_usersdelete')">
                    <v-btn @click="viewDelete(items.filter(i => i.checkbox == true), 'delete')" v-if="items.some(i => i.checkbox == true)" class="mt-4" tile small color="error" type="button"><v-icon left x-small>fa fa-trash</v-icon> Remove Profile</v-btn>
                    </span>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <addprofile :dialog="ma.modal_profile" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></addprofile>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import alert from '.././modals/AlertStatus';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import alertdelete from '../modals/AlertDeleteProfile';
import addprofile from '.././modals/CreateProfileExistingCustomer';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'SMS',
    title: 'SMS',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Message',
                disabled: true,
                href: '#',
                },

                {
                text: 'SMS',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'ID', width: '3%' },
                { text: 'Mobile No.', value: 'number', width: '10%' },
                { text: 'Message', value: 'fullmessage', width: '15%' },
                { text: 'Created Date', value: 'createdDateTime', width: '10%' },
                { text: 'Sent Status', value: 'status', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_delete: false,
                modal_profile: false,
                readonly: true,
                allInfo: [],
                item: {}
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            dropitems: {},
            totalrecords: '',
            tableselected: [],
        }
    },

    components: {alert, alertbox2, alertdelete, addprofile},

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        }
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

            else{
                return '';
            }
        },
    },

    async created(){
        await this.listItems(1);
    },

    methods: {
        async listItems(pg){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getMessageSMS", {resultpage: pg > 0 ? this.page = pg : this.page, search: this.search});

                let dt = response.data.record;
                
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                if (response.data.code === 'AP000'){
                    if (this.$route.query.created == true){
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Push Created!`;
                        this.$router.replace({'query': null});
                    }
                }

                for(var i=0; i<dt.length; i++){
                        dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                        dt[i].createdDateTime = dt[i].createdDateTime == null ? "N/A" : moment(new Date(dt[i].createdDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                }

                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                
            }

            catch(err){
                console.log(err);
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'reset'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'add'){
                this.ma.modal_profile = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.dropitems;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewDelete(row, flag){
            console.log(row);
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewCreate(){
            this.$router.push({ name: "Create Service User"});
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Operator Profile Details", params: {id: row.loginEmailID}, query: {row: JSON.stringify(row)}});
        },

        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_delete = value;
                this.ma.modal_profile = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            if (value.flag === 'add'){
                this.ma.modal_profile = false;
                this.addProfileExistingUser(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_status = false;
                    this.userReset();
                }

                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile();
                }
            }
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        validateArr(arr){
            let truth = arr.some(item => {
                return item === true;
            });

            return truth;
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 0){ return 'Enable'}
                    else if (val === 1){return 'Disable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 0){ return 'green'}
                    else if (val === 1){return 'red'}
                    else {return 'grey'}
            }
        },
    }
}
</script>